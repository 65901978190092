<script setup lang="ts">
import { PhCaretLeft, PhCaretRight, PhXCircle } from '@phosphor-icons/vue';
import { IconButton } from '@laam/ui/base';
import type { NodesSchema } from '~/data/nodes.ts';
import type { FeedNode } from '.';
const { $eventClient } = useNuxtApp();
interface NodesBar {
	feedNodes: FeedNode[];
	showNew: boolean;
}
defineProps<NodesBar>();

const route = useRoute();
const router = useRouter();

const { newArrivalsFilterExperiment } = useStatsigExperiment();

const nodeIds = computed(() => {
	const query = { ...route.query };

	if (query.node_id) {
		return (query.node_id as string).split(',');
	}
	return [];
}); // node ids in url query

const scrollContainerRef = ref<HTMLElement | null>(null);
const showLeftIcon = ref(false);
const showRightIcon = ref(true);

const checkScrollPosition = () => {
	if (scrollContainerRef.value) {
		const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.value;

		// Show left icon if we've scrolled at all
		showLeftIcon.value = scrollLeft > 0;

		// Show right icon if we haven't reached the end
		// Adding a small buffer (1px) to account for rounding errors
		showRightIcon.value = Math.abs(scrollWidth - clientWidth - scrollLeft) > 1;
	}
};

const handleScroll = (goRight: boolean) => {
	if (scrollContainerRef.value) {
		if (goRight) {
			scrollContainerRef.value.scrollBy({
				left: 200,
				behavior: 'smooth',
			});
		} else {
			scrollContainerRef.value.scrollBy({
				left: -200,
				behavior: 'smooth',
			});
		}
	}
};

const handleNodeDeselect = (node: NodesSchema[0]) => {
	if (route.name === 'brands-handle') {
		const query = { ...route.query };

		if (nodeIds.value.includes(node.id.toString())) {
			const updatedNodeIds = nodeIds.value.filter(
				(id: string) => id !== node.id.toString(),
			);
			if (!updatedNodeIds.length) {
				delete query.node_id;
			} else {
				query.node_id = updatedNodeIds.join(',');
			}

			router.replace({ path: route.path, query: query });
		}
	}
};

const handleNodeClick = (node: NodesSchema[0]) => {
	if (route.name === 'brands-handle') {
		const query = { ...route.query };
		if (!nodeIds.value.includes(node.id.toString())) {
			const ids = [...nodeIds.value];
			ids.push(node.id.toString());
			query.node_id = ids.join(',');

			router.push({
				path: route.path,
				query: query,
			});
		}
	}
	logEvent(node.title, node.id.toString());
};

const isNewArrivalsEnabled = computed(() => {
	return !!route.query.period;
});

const handleNewClick = () => {
	const query = { ...route.query };
	query.period = newArrivalsFilterExperiment?.value?.period || '30d';
	router.push({ path: route.path, query });
};

const handleNewDeselect = () => {
	const query = { ...route.query };
	delete query.period;
	router.push({ path: route.path, query });
};

const logEvent = (title: string, id: string) => {
	$eventClient.sendEvent('node-button-clicked', {
		title: title,
		id: id,
		url: window.location.href,
	});
};

// Watch for scroll events
onMounted(() => {
	scrollContainerRef.value?.addEventListener('scroll', checkScrollPosition);
	// Check initial scroll position
	checkScrollPosition();
});

onBeforeUnmount(() => {
	scrollContainerRef.value?.removeEventListener('scroll', checkScrollPosition);
});
</script>
<template>
	<div class="flex relative items-center justify-between">
		<IconButton
			v-if="showLeftIcon && !isMobileView()"
			rounded="full"
			variant="secondary"
			size="xs"
			class="top-[30px] absolute z-10 left-[-17px]"
			@click="handleScroll(false)"
		>
			<PhCaretLeft />
		</IconButton>
		<div
			ref="scrollContainerRef"
			class="w-full lg:px-none h-fit px-3xl relative overflow-x-scroll no-scrollbar"
		>
			<div class="flex flex-start gap-md w-full min-w-max">
				<!-- New Bar -->
				<div
					v-if="showNew"
					class="cursor-pointer relative text-center text-xs font-medium text-gray-800"
					@click.prevent="() => handleNewClick()"
				>
					<div
						v-if="!!isNewArrivalsEnabled"
						class="absolute top-0 right-0 w-[15px] h-[15px] bg-white rounded-lg"
						@click.stop="() => handleNewDeselect()"
					>
						<PhXCircle size="15" color="black" weight="fill" />
					</div>

					<div
						class="px-lg py-md bg-gray-100 rounded-xs"
						:class="isNewArrivalsEnabled ? 'border-[1px] border-black' : ''"
					>
						{{ 'New' }}
					</div>
				</div>
				<div v-for="(item, idx) in feedNodes" :key="idx">
					<NuxtLink
						v-if="item.href && !route.path.includes('brands')"
						:to="`/${item.href}`"
						class="cursor-pointer relative text-center text-xs font-medium text-gray-800"
						@click.prevent="() => handleNodeClick(item)"
					>
						<div
							v-if="nodeIds.includes(item.id.toString())"
							class="absolute top-0 right-0 w-[15px] h-[15px] bg-white rounded-lg"
							@click.stop="() => handleNodeDeselect(item)"
						>
							<PhXCircle size="15" color="black" weight="fill" />
						</div>

						<div
							class="px-lg py-md bg-gray-100 rounded-xs"
							:class="
								nodeIds.includes(item.id.toString())
									? 'border-[1px] border-black'
									: ''
							"
						>
							{{ item.title }}
						</div>
					</NuxtLink>
					<div
						v-else
						class="cursor-pointer relative text-center text-xs font-medium text-gray-800"
						@click.prevent="() => handleNodeClick(item)"
					>
						<div
							v-if="nodeIds.includes(item.id.toString())"
							class="absolute top-0 right-0 w-[15px] h-[15px] bg-white rounded-lg"
							@click.stop="() => handleNodeDeselect(item)"
						>
							<PhXCircle size="15" color="black" weight="fill" />
						</div>

						<div
							class="px-lg py-md bg-gray-100 rounded-xs"
							:class="
								nodeIds.includes(item.id.toString())
									? 'border-[1px] border-black'
									: ''
							"
						>
							{{ item.title }}
						</div>
					</div>
				</div>
			</div>
		</div>
		<IconButton
			v-if="showRightIcon && !isMobileView()"
			rounded="full"
			variant="secondary"
			size="xs"
			class="top-[30px] absolute z-10 right-[-17px]"
			@click="handleScroll(true)"
		>
			<PhCaretRight />
		</IconButton>
	</div>
</template>
